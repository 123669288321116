import { LeagueSearchResultGameCategory } from '@arland-bmnext/api-data'
import { FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import React from 'react'
import { useSportsContext } from '../../../context/sports.context'
import { getBettableCategories } from '../../../lib/odds'
import { sortArray } from '../../../util/array'
import SportIcon from '../SportIcon'
import SportLeagueBrowserCategoryItem from './CategoryItem'

type SportLeagueBrowserSportsItemProps = {
  sportId: number
  sportShortSign: string
  sportName: string
  searchResultSportGameCategories?: LeagueSearchResultGameCategory[]
  favoriteLeagues: FavoriteLeagueDTO[]
  languageId: number
  leagueSearchTerm?: string
  startDate?: string
  categoriesContainingFavs: any
  endDate?: string
  mode: 'add-favorites' | 'slb'
}
const SportLeagueBrowserSportsItem = ({
  sportId,
  sportShortSign,
  sportName,
  searchResultSportGameCategories = null,
  leagueSearchTerm = '',
  favoriteLeagues,
  languageId,
  categoriesContainingFavs,
  startDate,
  endDate,
  mode,
}: SportLeagueBrowserSportsItemProps) => {
  const { slbState, updateSlbState } = useSportsContext()
  const categories = slbState.categories[sportId]
  const sortedCategories = slbState.sortedCategories[sportId]
  const topCategories = slbState.topCategories[sportId]
  const isOpen = slbState?.activeSportIds[sportId] === true || searchResultSportGameCategories != null

  const onSportItemClick = async () => {
    if (searchResultSportGameCategories == null && (categories == null || categories.length == 0))
      await loadCategories()
    updateSlbState('activeSportIds', { ...slbState.activeSportIds, [sportId]: !isOpen })
  }

  const loadCategories = async () => {
    const loadedCategories = await getBettableCategories(sportId, startDate, endDate, languageId)
    const _topCategories = sortArray(loadedCategories, 'sortKey', 'desc').slice(0, 5)
    const _sortedCategories = sortArray(loadedCategories, 'name', 'asc').filter(
      (category) => _topCategories?.find((topCategory) => category.id == topCategory.id) == null,
    )
    updateSlbState('categories', { ...slbState.categories, [sportId]: loadedCategories })
    updateSlbState('topCategories', { ...slbState.topCategories, [sportId]: _topCategories })
    updateSlbState('sortedCategories', { ...slbState.sortedCategories, [sportId]: _sortedCategories })
  }

  return (
    <div className="slb-sport flex flex-col bg-slbSportItem text-slbSportItemContrast rounded-md border-[1px] border-neutral">
      <div className="slb-sport-item flex justify-between items-center p-2">
        <div className="slb-sport-item-inner flex items-center justify-between w-full hover:underline">
          <div className="flex flex-grow space-x-2 items-center text-xs cursor-pointer" onClick={onSportItemClick}>
            <div className="slb-sport-item-icon text-xl bg-gray-500 bg-opacity-10 p-1 rounded-full">
              <SportIcon sportShortSign={sportShortSign} />
            </div>
            <span className={`slb-sport-item-name ${isOpen ? 'font-bold' : ''}`}>{sportName}</span>
          </div>
        </div>
      </div>

      {isOpen && (
        <>
          {searchResultSportGameCategories == null && categories?.length > 0 && (
            <div className="slb-sport-categories flex flex-col space-y-2 p-2 pt-0">
              {topCategories?.map((category) => (
                <SportLeagueBrowserCategoryItem
                  categoryId={category.id}
                  categoryName={category.name}
                  categoryShortSign={category.shortSign}
                  categoryCountryId={category.countryId}
                  leagueSearchTerm={leagueSearchTerm}
                  sportId={sportId}
                  languageId={languageId}
                  favoriteLeagues={favoriteLeagues}
                  categoriesContainingFavs={categoriesContainingFavs}
                  startDate={startDate}
                  endDate={endDate}
                  mode={mode}
                  gameCount={category.gameCount}
                  key={category.id}
                />
              ))}

              {topCategories?.length > 0 && sortedCategories?.length > 0 && <div></div>}

              {sortedCategories?.map((category) => (
                <SportLeagueBrowserCategoryItem
                  categoryId={category.id}
                  categoryName={category.name}
                  categoryShortSign={category.shortSign}
                  categoryCountryId={category.countryId}
                  leagueSearchTerm={leagueSearchTerm}
                  sportId={sportId}
                  languageId={languageId}
                  favoriteLeagues={favoriteLeagues}
                  categoriesContainingFavs={categoriesContainingFavs}
                  startDate={startDate}
                  endDate={endDate}
                  mode={mode}
                  gameCount={category.gameCount}
                  key={category.id}
                />
              ))}
            </div>
          )}

          {searchResultSportGameCategories != null && searchResultSportGameCategories.length > 0 && (
            <div className="slb-sport-categories flex flex-col space-y-2 p-2 pt-0">
              {searchResultSportGameCategories.map((category) => (
                <SportLeagueBrowserCategoryItem
                  categoryId={category.categoryId}
                  categoryName={category.categoryName}
                  categoryShortSign={category.categoryShortSign}
                  categoryCountryId={null}
                  searchResultCategoryLeagues={category.leagues}
                  leagueSearchTerm={leagueSearchTerm}
                  sportId={sportId}
                  languageId={languageId}
                  favoriteLeagues={favoriteLeagues}
                  categoriesContainingFavs={categoriesContainingFavs}
                  startDate={startDate}
                  endDate={endDate}
                  mode={mode}
                  key={category.categoryId}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SportLeagueBrowserSportsItem
